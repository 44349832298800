@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

body {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	/* height: 50vh; */
	margin: 50px 50px;
}


h1 {
	text-align: center;
	color: darkblue;
}

h2 {
	text-align: center;
	color: darkblue;
}

p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

span {
	font-size: 12px;
}

a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

button {
	/*
	border-radius: 20px;
	border: 1px solid #459871;
	background-color: #459871;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	*/

	border: none;
    background-color: #0067b8;
    min-width: 80px;
    width: auto;
    height: 30px;
    padding: 4px 20px 6px 20px;
    border-style: solid;
    border-width: 1px;
    transition: background 0s;
    color: rgb(255, 255, 255);
    cursor: pointer;
    margin-bottom: 8px;
    -ms-user-select: none;
    -moz-transition: background 0s;
    -webkit-transition: background 0s;
    -o-transition: background 0s;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

button:active {
	cursor: pointer
}

button:disabled {
	background: rgb(212, 227, 251);
	cursor: not-allowed;
}

button:focus {
	background: rgb(212, 227, 251);
}

button:hover {
	background: rgb(212, 227, 251);
}

button:hover:disabled
{
    background: rgb(212, 227, 251);
    cursor: not-allowed;
}

form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

input {
	/*
	background-color: #eee;
	border: none;
	padding: 8px;
	margin: 8px 0;
	width: 100%;
	*/

	margin-bottom: 8px;
	border: solid 1px #BABABA;
	padding: 0px 3px 0px 3px;
	font-size: 1.0em;
	/*width: 100%;*/
	height: 28px;
	max-width: 100%;
}

input:active {
	cursor: default;
}

input:disabled {
	cursor: not-allowed;
}

.container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	/* position: relative; */
	/* overflow: hidden; */
	width: 768px;
	/* max-width: 100%; */
	min-height: 350px;
}

.form-container {
	margin-top: 20px;
	height: 90%;
    width: 100%;
}

.form-container div {
    width: 100%;
}

.form-container input,label {
	width: 50%;
}

.alert-container {
	height: 10%;
    width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.login-form {
  background-color: #282c34;
}

.alert-container {
	border-radius: 10px;
	padding: 20px;
	color: white;

	width: 80%;
	margin-top: 20px;
	margin-left: 10%;
	margin-right: 10%;
}

.alert-error {
	background-color: #f44336; /* Red */
}

.alert-sucesss {
	background-color: #04AA6D; /* Green */
}

/* svg {
	border-color: #282c34;
	border-width: 1px;
	border-style: solid;
	width: auto;
} */

path {
	fill: darkblue;
}